import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import css from './SignupTab.module.scss'
import { Field, Form, Formik, FormikProps } from 'formik'
import { TextField } from 'formik-material-ui'
import StyledMuiButton from '../../../components/StyledMuiButton/StyledMuiButton'
import { CircularProgress } from '@material-ui/core'
import { FormikHelpers } from 'formik/dist/types'
import * as yup from 'yup'
import { ThunkDispatch } from 'redux-thunk'
import { login, signUp } from '../../../ducks/auth.duck'
import { AlertManager, withAlert } from 'react-alert'
import { connect } from 'react-redux'

interface PropsI
  extends RouteComponentProps<any, any, { from: string; email: string }>,
    MapDispatchPropsI,
    MapStatePropsI {
  alert: AlertManager
  email?: string
}

interface FormFieldsI {
  email: string
  password: string
  firstName: string
  lastName: string
  displayName: string
}

const SignupTab: React.FC<PropsI> = (props) => {
  const { signUpA, loginA, alert, location, history } = props

  const email = location?.state?.email

  const onSubmit = (values: FormFieldsI, formikHelpers: FormikHelpers<FormFieldsI>) => {
    signUpA(values)
      .then((res) => {
        loginA(values.email, values.password)
          .then((loginRes) => {
            if (loginRes.data.roles.length === 0) {
              setTimeout(() => {
                history.push(location?.state?.from || '/my-companies')
              }, 2000)
            }

            if (loginRes.data.roles.includes('hr')) {
              history.push(location?.state?.from || '/hr')
            }

            if (loginRes.data.roles.includes('employee')) {
              history.push(location?.state?.from || '/employee')
            }

            if (res.data.roles.includes('coach')) {
              history.push(location?.state?.from || '/coach')
            }

            history.push(location?.state?.from || '/')
            formikHelpers.setSubmitting(false)
          })
          .catch(() => {
            formikHelpers.setSubmitting(false)
          })
      })
      .catch((err) => {
        alert.error(err)
        formikHelpers.setSubmitting(false)
      })
  }

  const validationSchema = yup.object({
    firstName: yup.string().label('First name').required(),
    displayName: yup.string().label('Display name').required(),
    lastName: yup.string().label('Last name').required(),
    email: yup.string().label('Email').required().email(),
    password: yup.string().label('Password').min(8).required(),
  })

  const initialValues: FormFieldsI = {
    email: email || '',
    password: '',
    displayName: '',
    lastName: '',
    firstName: '',
  }

  return (
    <div className={css.base}>
      <p className={css.title}>Signup to V-Corp</p>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {(formikProps: FormikProps<FormFieldsI>) => {
          return (
            <Form className={css.form}>
              <Field
                className={css.input_field}
                component={TextField}
                name="firstName"
                variant="outlined"
                label="First name"
                color="primary"
                required
              />

              <Field
                className={css.input_field}
                component={TextField}
                name="lastName"
                variant="outlined"
                label="Last name"
                color="primary"
                required
              />

              <Field
                className={css.input_field}
                component={TextField}
                name="displayName"
                variant="outlined"
                label="Display Name"
                color="primary"
                required
              />

              <Field
                className={css.input_field}
                component={TextField}
                name="email"
                variant="outlined"
                label="Email"
                color="primary"
                required
                disabled={!!email}
              />

              <Field
                className={css.input_field}
                component={TextField}
                type="password"
                name="password"
                variant="outlined"
                label="Password"
                color="primary"
                required
                autoComplete="new-password"
              />

              <StyledMuiButton
                className={css.submit_button}
                color="primary"
                variant="contained"
                type="submit"
                disableElevation
                disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
              >
                {formikProps.isSubmitting ? <CircularProgress size={25} /> : 'Signup'}
              </StyledMuiButton>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

interface MapStatePropsI {}

interface MapDispatchPropsI {
  signUpA: (values: any) => Promise<ApiResponseI>
  loginA: (email: string, password: string) => Promise<ApiResponseI>
}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {
    currentUser: state.user.currentUser,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActionI>): MapDispatchPropsI => {
  return {
    signUpA: (values: any) => dispatch(signUp(values)),
    loginA: (email: string, password: string) => dispatch(login(email, password)),
  }
}

export default withAlert()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupTab)))
