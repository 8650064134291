import React from 'react'
import { Alert } from '@material-ui/lab'
import { AlertComponentPropsWithStyle } from 'react-alert'

const ReactAlertTemplate: React.FC<AlertComponentPropsWithStyle> = (props) => {
  const { message, style, options, close } = props

  return (
    <Alert onClose={close} style={style} severity={options.type}>
      {message}
    </Alert>
  )
}

export default ReactAlertTemplate
