import React, { useEffect, useState } from 'react'
import NavBar from '../../../components/NavBar/NavBar'
import PageWrapper from '../../../components/PageWrapper/PageWrapper'
import CoachDashboardWrapper from '../../../components/CoachDashboardWrapper/CoachDashboardWrapper'
import BaseTable from '../../../components/BaseTable/BaseTable'
import { fetchCoachOrders } from './MyOrders.helper'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import TableActions from '../../../components/TableActions/TableActions'
import { vivekaUrl } from '../../../env-config'

const MyOrders: React.FC = () => {
  const [dataArr, setDataArr] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    fetchCoachOrders()
      .then((res) => {
        setDataArr(res.data)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [])
  const columns: any[] = [
    {
      Header: 'Company',
      accessor: 'company.name',
    },
    {
      Header: 'Curriculum',
      accessor: 'curriculum.name',
    },
    {
      Header: 'Listing',
      accessor: 'listing.name',
    },
    {
      Header: 'Attendee',
      accessor: 'attendee.displayName',
    },
    {
      Header: 'Listing Price',
      accessor: 'price',
      Cell: (tableProps: any) => {
        return tableProps.value
          ? `$${tableProps.value.toLocaleString()}`
          : `$${(0).toLocaleString()}`
      },
    },
    // {
    //   Header: 'Status',
    //   accessor: 'status',
    // },
    // {
    //   Header: 'Is Complete',
    //   accessor: 'isComplete',
    //   Cell: (tableProps: any) => {
    //     return <div>{tableProps.value ? <FontAwesomeIcon icon={faCheck} /> : 'No'}</div>
    //   },
    // },
    {
      Header: 'Actions',
      accessor: 'coachActivityId',
      Cell: (tableProps: any) => (
        <TableActions
          actions={[
            {
              icon: faEye,
              action: () =>
                window.open(`${vivekaUrl}/v-coach/clients-activities/${tableProps.value}`, '_self'),
              tooltip: 'View',
            },
          ]}
        />
      ),
    },
  ]

  return (
    <PageWrapper>
      <NavBar />

      <CoachDashboardWrapper title="My Orders" page="my-orders">
        <BaseTable columns={columns} data={dataArr} loading={isLoading} />
      </CoachDashboardWrapper>
    </PageWrapper>
  )
}

export default MyOrders
