import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import css from './EmployeeSelectListingPage.module.scss'
import { enrollRequest, fetchListing } from './EmployeeSelectListingPage.helper'
import Carousel from 'react-material-ui-carousel'
import Button from '@material-ui/core/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { useConfirm } from 'material-ui-confirm'
import { useAlert } from 'react-alert'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import { fetchMyBudgetData } from '../EmployeeCompanyPage/EmployeeCompanyPage.helper'

interface PropsI extends RouteComponentProps<{ curriculumId: string; listingId: string }> {}

const EmployeeSelectListingPage: React.FC<PropsI> = (props) => {
  const { match, history } = props
  const { listingId, curriculumId } = match.params

  const [data, setData] = useState<any>()
  const [budgetData, setBudgetData] = useState<any>()

  useEffect(() => {
    fetchListing(curriculumId, listingId).then((res) => {
      setData(res.data)
    })

    fetchMyBudgetData().then((res) => setBudgetData(res.data))
  }, [listingId, curriculumId])

  const confirm = useConfirm()
  const alert = useAlert()

  const listingPrice = data?.listing?.price

  const enrollClickHandler = () => {
    confirm().then(() => {
      enrollRequest(curriculumId, listingId)
        .then((res) => {
          alert.success(res.message)
          history.push('/employee/company/my-curriculums')
        })
        .catch((err) => {
          alert.error(err)
        })
    })
  }

  const curriculum = budgetData?.curriculums?.find(
    (x: any) => x.curriculumId === Number(curriculumId)
  )
  // console.log(data?.listing)

  return (
    <MainDashboardWrapper
      // title={data?.listing ? `Enroll In ${data?.listing.name}` : ''}
      title={'Enroll'}
      page="client-company"
    >
      <div className={css.base}>
        <div className={css.budget_details}>
          <div className={css.budget_card}>
            <p>Remaining Discretionary Company Budget</p>
            <p>${(budgetData?.company?.discretionaryBudget || 0).toLocaleString()}</p>
          </div>
          <div className={css.budget_card}>
            <p>Remaining Required Company Budget</p>
            <p>${(budgetData?.company?.requiredBudget || 0).toLocaleString()}</p>
          </div>
          <div className={css.budget_card}>
            <p>Remaining Curriculum Budget</p>
            <p>${curriculum?.currentBudget?.toLocaleString()}</p>
          </div>
        </div>

        <p>Listing name: {data?.listing?.name}</p>
        <p>Price: ${listingPrice?.toLocaleString()}</p>
        <p>Coach: {data?.listing?.owner.displayName}</p>
        <p>Curriculum type: {curriculum?.curriculum?.type?.toUpperCase()}</p>
        <Carousel className={css.carousel}>
          {data?.listing?.images.map((x: string, index: number) => {
            return <img key={index} alt="listing" src={x} />
          })}
        </Carousel>
        {/*<Card>*/}
        {/*  <CardHeader title={'By'} />*/}
        {/*  <CardContent>{data?.listing?.owner.displayName}</CardContent>*/}
        {/*</Card>*/}
        <Button
          className={css.enroll_button}
          variant="outlined"
          color="primary"
          startIcon={<FontAwesomeIcon icon={faPlusSquare} />}
          onClick={enrollClickHandler}
        >
          Enroll
        </Button>
      </div>
    </MainDashboardWrapper>
  )
}

export default withRouter(EmployeeSelectListingPage)
