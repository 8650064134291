import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { vivekaUrl } from '../../env-config'

interface PropsI extends RouteComponentProps<{ access_token: string; refresh_token: string }> {}

const AutoAuthLoginPage: React.FC<PropsI> = ({ match }) => {
  useEffect(() => {
    localStorage.setItem('access_token', match.params.access_token)
    localStorage.setItem('refresh_token', match.params.refresh_token)
    // window.open(`${vivekaUrl}`, '_self')
    window.close()
  }, [match.params.access_token, match.params.refresh_token])
  return <div />
}

export default withRouter(AutoAuthLoginPage)
