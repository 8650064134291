import { authAxios } from '../../helpers/axios'
import { backendUrl } from '../../env-config'

export const fetchMyCompanies = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.get(`${backendUrl}/user/my-companies`)
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}
