import * as env from 'dotenv'
import React from 'react'
import ReactDOM from 'react-dom'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import { Provider } from 'react-redux'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { MuiThemeProvider } from '@material-ui/core'
import { theme } from './muiTheme'
import configureStore from './redux/store'
import ReactAlertTemplate from './components/ReactAlertTemplate/ReactAlertTemplate'
import { ConfirmProvider } from 'material-ui-confirm'
// import { setAxiosInterceptors } from './helpers/axios'
env.config()

// setAxiosInterceptors()

ReactDOM.render(
  <Provider store={configureStore()}>
    <AlertProvider
      template={ReactAlertTemplate}
      position={positions.BOTTOM_CENTER}
      timeout={5000}
      offset="30px"
      transition={transitions.FADE}
    >
      {/*<React.StrictMode>*/}
      <MuiThemeProvider theme={theme}>
        <ConfirmProvider>
          <App />
        </ConfirmProvider>
      </MuiThemeProvider>
      {/*</React.StrictMode>*/}
    </AlertProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
