import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import AuthRoute from './components/AuthRoute/AuthRoute'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { setAuthentication } from './ducks/auth.duck'
import { routes } from './routeConfiguration.tsx'
import { fetchCurrentUser } from './ducks/user.duck'
import NotFoundPage from './containers/NotFoundPage/NotFoundPage'

interface PropsI extends MapStatePropsI, MapDispatchPropsI {}

const App: React.FC<PropsI> = (props) => {
  const { setAuthenticationA, pageLoadA, isAuthenticated } = props
  useEffect(() => {
    setAuthenticationA()
  }, [setAuthenticationA])

  useEffect(() => {
    if (isAuthenticated) {
      pageLoadA()
    }
  }, [isAuthenticated, pageLoadA])

  return (
    <BrowserRouter>
      <Switch>
        {routes.map((item: RouteI, index: number) => {
          const RouteComponent = item.component
          if (item.private) {
            return (
              <AuthRoute key={index} path={item.path} exact={item.exact} roles={item.roles || []}>
                <RouteComponent />
              </AuthRoute>
            )
          } else {
            return (
              <Route key={index} path={item.path} exact={item.exact}>
                <RouteComponent />
              </Route>
            )
          }
        })}
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

interface MapStatePropsI {
  isAuthenticated: boolean
}

interface MapDispatchPropsI {
  setAuthenticationA: () => void
  pageLoadA: () => Promise<ApiResponseI>
}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActionI>): MapDispatchPropsI => {
  return {
    pageLoadA: () => dispatch(fetchCurrentUser()),
    setAuthenticationA: () => dispatch(setAuthentication()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App)
