import React, { useEffect, useState } from 'react'
import BaseTable from '../../../../components/BaseTable/BaseTable'
import { Column } from 'react-table'
import moment from 'moment'
import { Tooltip } from '@material-ui/core'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { fetchEmployeeEnrollments } from '../HRCurriculumEmployeeViewPage.helper'

interface PropsI extends RouteComponentProps<{ id: string; empId: string; tab: string }> {}

const HRCurriculumEmployeeViewEnrollView: React.FC<PropsI> = (props) => {
  const { match } = props
  const [enrollments, setEnrollment] = useState<any[]>([])
  useEffect(() => {
    fetchEmployeeEnrollments(match.params.id, match.params.empId)
      .then((res) => setEnrollment(res.data))
      .catch((err) => console.log(err))
  }, [match.params.empId, match.params.id])

  // console.log(enrollments)

  const columns: Column[] = [
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: (tableProps: any) => {
        const dateString = moment(tableProps.value).format('DD / M / YYYY')
        const fromNow = moment(tableProps.value).fromNow()
        return (
          <Tooltip title={fromNow}>
            <p>{dateString}</p>
          </Tooltip>
        )
      },
    },

    {
      Header: 'Amount',
      accessor: 'price',
      Cell: (tableProps: any) => {
        return tableProps.value
          ? `$${tableProps.value.toLocaleString()}`
          : `$${(0).toLocaleString()}`
      },
    },
    {
      Header: 'Coach',
      accessor: 'coach.displayName',
    },
    {
      Header: 'Program',
      accessor: 'listing.name',
    },
  ]

  return (
    <div>
      <BaseTable columns={columns} data={enrollments} />
    </div>
  )
}

export default withRouter(HRCurriculumEmployeeViewEnrollView)
