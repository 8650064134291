import { AxiosError } from 'axios'
import { Dispatch } from 'redux'
import { backendUrl } from '../env-config'
import { authAxios } from '../helpers/axios'

// ================ Action types ================ //

export const CATEGORIES_FETCH_ALL_REQUEST = 'app/formUtils/CATEGORIES_FETCH_ALL_REQUEST'
export const CATEGORIES_FETCH_ALL_COMPLETE = 'app/formUtils/CATEGORIES_FETCH_ALL_COMPLETE'

export const USERS_FETCH_REQUEST = 'app/formUtils/USERS_FETCH_REQUEST'
export const USERS_FETCH_COMPLETE = 'app/formUtils/USERS_FETCH_COMPLETE'

// export const COMPANY_EMPLOYEES_FETCH_REQUEST = 'app/formUtils/COMPANY_EMPLOYEES_FETCH_REQUEST'
// export const COMPANY_EMPLOYEES_FETCH_COMPLETE = 'app/formUtils/COMPANY_EMPLOYEES_FETCH_COMPLETE'

export const LISTINGS_FETCH_REQUEST = 'app/formUtils/LISTINGS_FETCH_REQUEST'
export const LISTINGS_FETCH_COMPLETE = 'app/formUtils/LISTINGS_FETCH_COMPLETE'

// ================ Reducer ================ //

const initialState: ReduxFormUtilsStateI = {
  categories: [],
  categoriesInProgress: false,
  users: [],
  usersInProgress: false,
  listings: [],
  listingsInProgress: false,
  // employees: [],
  // employeesInProgress: false,
}

export default function reducer(state = initialState, action: AppActionI) {
  const { type, payload } = action
  switch (type) {
    case CATEGORIES_FETCH_ALL_REQUEST:
      return {
        ...state,
        categoriesInProgress: true,
      }

    case CATEGORIES_FETCH_ALL_COMPLETE:
      return {
        ...state,
        categories: payload || [],
        categoriesInProgress: false,
      }

    case USERS_FETCH_REQUEST:
      return {
        ...state,
        usersInProgress: true,
      }

    case USERS_FETCH_COMPLETE:
      return {
        ...state,
        users: payload || [],
        usersInProgress: false,
      }

    case LISTINGS_FETCH_REQUEST:
      return {
        ...state,
        listingsInProgress: true,
      }

    case LISTINGS_FETCH_COMPLETE:
      return {
        ...state,
        listings: payload || [],
        listingsInProgress: false,
      }

    // case COMPANY_EMPLOYEES_FETCH_REQUEST:
    //   return {
    //     ...state,
    //     employees: payload || [],
    //     employeesInProgress: false,
    //   }
    //
    // case COMPANY_EMPLOYEES_FETCH_COMPLETE:
    //   return {
    //     ...state,
    //     employeesInProgress: false,
    //   }

    default:
      return state
  }
}

// ================ Action creators ================ //

const categoriesFetchAllRequest = (): AppActionI => ({ type: CATEGORIES_FETCH_ALL_REQUEST })
const categoriesFetchAllComplete = (categories?: CategoryModelI[]): AppActionI => ({
  type: CATEGORIES_FETCH_ALL_COMPLETE,
  payload: categories,
})

const usersFetchRequest = (): AppActionI => ({ type: USERS_FETCH_REQUEST })
const usersFetchComplete = (users?: any): AppActionI => ({
  type: USERS_FETCH_COMPLETE,
  payload: users,
})

const listingsFetchRequest = (): AppActionI => ({ type: LISTINGS_FETCH_REQUEST })
const listingsFetchComplete = (listings?: any): AppActionI => ({
  type: LISTINGS_FETCH_COMPLETE,
  payload: listings,
})

// const employeesFetchRequest = (): AppActionI => ({ type: USERS_FETCH_REQUEST })
// const employeesFetchComplete = (users?: any): AppActionI => ({
//   type: USERS_FETCH_COMPLETE,
//   payload: users,
// })

// ================ Thunks ================ //

export const fetchAllCategories = () => async (dispatch: Dispatch, getState: () => AppStateI) => {
  dispatch(categoriesFetchAllRequest())

  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.get<ApiResponseI>(`${backendUrl}/form-utils/categories`)
      dispatch(categoriesFetchAllComplete(res.data.data))
      resolve(res.data)
    } catch (e: any) {
      dispatch(categoriesFetchAllComplete())
      const err: AxiosError<ApiResponseI> = e
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchUserBySearchText = (searchText: string) => async (
  dispatch: Dispatch,
  getState: () => AppStateI
) => {
  dispatch(usersFetchRequest())

  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(
        `${backendUrl}/form-utils/fetch-users-by-search-text`,
        { searchText }
      )
      dispatch(usersFetchComplete(res.data.data))
      resolve(res.data)
    } catch (e: any) {
      dispatch(usersFetchComplete())
      const err: AxiosError<ApiResponseI> = e
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchListingByText = (searchText: string) => async (
  dispatch: Dispatch,
  getState: () => AppStateI
) => {
  dispatch(listingsFetchRequest())

  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(
        `${backendUrl}/form-utils/fetch-listing-by-search-text`,
        { searchText }
      )
      dispatch(listingsFetchComplete(res.data.data))
      resolve(res.data)
    } catch (e: any) {
      dispatch(listingsFetchComplete())
      const err: AxiosError<ApiResponseI> = e
      reject(err.response?.data.message || err.message)
    }
  })
}
