import React from 'react'
import css from './CoachViewOfferPopup.module.scss'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import SmallRoundColoseButton from '../SmallRoundCloseButton/SmallRoundCloseButton'
import BaseTable from '../BaseTable/BaseTable'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import TableActions from '../TableActions/TableActions'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { vivekaUrl } from '../../env-config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ViewListingPopup from '../ViewListingPopup/ViewListingPopup'

interface PropsI extends RouteComponentProps {
  trigger: JSX.Element | ((isOpen: boolean) => JSX.Element)
  listings: any[]
  authorName: string
}

const CoachViewOfferPopup: React.FC<PropsI> = (props) => {
  const { trigger, listings, authorName } = props

  const history = useHistory()

  const columns: any[] = [
    {
      Header: 'Ad Name',
      accessor: 'name',
      Cell: (tableProps: any) => {
        console.log(tableProps)
        return (
          <a
            href={`${vivekaUrl}/l/${tableProps?.row?.original?.uuid}`}
            target="_blank"
            style={{
              // textDecoration: 'none',
              color: 'black',
            }}
          >
            {tableProps.value}
          </a>
        )
      },
    },
    {
      Header: 'Price',
      accessor: 'price',
      Cell: (tableProps: any) => {
        return tableProps.value
          ? `$${tableProps.value.toLocaleString()}`
          : `$${(0).toLocaleString()}`
      },
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
  ]

  return (
    <Popup trigger={trigger} modal>
      {(close: any) => (
        <div className={css.base}>
          <SmallRoundColoseButton onClick={close} className={css.close_button} />
          <p className={css.title}>{authorName}'s Offerings</p>
          <BaseTable columns={columns} data={listings} />
        </div>
      )}
    </Popup>
  )
}

export default withRouter(CoachViewOfferPopup)
