import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import css from './HRCoachAddPage.module.scss'
import { ThunkDispatch } from 'redux-thunk'
import {
  Avatar,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from '@material-ui/core'
// import { useDebouncedCallback } from 'use-debounce'
import { filterCoaches, selectACoach } from './HRCoachAddPage.helper'
import { useAlert } from 'react-alert'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import * as yup from 'yup'
import { TextField } from 'formik-material-ui'
import Select, { ValueType } from 'react-select'
import { fetchAllCategories } from '../../../helpers/generalApiCalls'
import { FormikHelpers } from 'formik/dist/types'
import ReactHtmlParser from 'react-html-parser'
import { decode } from 'html-entities'
import LogoOnlyCircle from '../../../assets/viveka-logo-only-circle.png'
import CoachViewOfferPopup from '../../../components/CoachViewOfferPopup/CoachViewOfferPopup'

interface PropsI extends MapStatePropsI, MapDispatchPropsI {}

interface FormI {
  search?: string
  categories?: string[] | number[]
  location?: string
  minPrice?: string
  maxPrice?: string
  coachType?: string
}

const HRCoachAddPage: React.FC<PropsI> = () => {
  const [coaches, setCoaches] = useState([])

  const initialValues: FormI = {
    categories: [],
    search: '',
    location: '',
    maxPrice: '',
    minPrice: '',
    coachType: '',
  }

  // const [initialValues, setInitialValues] = useState<FormI>(emptyInitialValue)
  const [categories, setCategories] = useState<any[]>([])
  // const [categoriesLoading, setCategoriesLoading] = useState(false)

  useEffect(() => {
    // setCategoriesLoading(true)
    fetchAllCategories()
      .then((res) => {
        // setCategoriesLoading(false)
        setCategories(res.data)
      })
      .catch((err) => {
        // setCategoriesLoading(false)
        console.log(err)
      })
  }, [])

  const alert = useAlert()

  // const fetchCoaches = useDebouncedCallback(
  //   (value: string) => {
  //     fetchUserBySearchText(value).then((res) => {
  //       setCoaches(res.data)
  //     })
  //   },
  //   600,
  //   { maxWait: 2000 }
  // )

  const onSubmit = (value: any, formikHelpers: FormikHelpers<FormI>) => {
    // console.log({ value })
    filterCoaches(value)
      .then((res) => {
        setCoaches(res.data)
        formikHelpers.setSubmitting(false)
      })
      .catch(() => {
        formikHelpers.setSubmitting(false)
      })
  }

  const addCoachButtonHandle = (id: number) => {
    selectACoach(id)
      .then((res) => {
        alert.success('Coach added to the Company')
      })
      .catch((err) => {
        alert.error(err)
      })
  }

  const validationSchema = yup.object({
    search: yup.string(),
    location: yup.string(),
    minPrice: yup.number(),
    maxPrice: yup.number(),
    coachType: yup.string(),
    categories: yup.array(),
  })

  // console.log(categories)

  return (
    <MainDashboardWrapper title="Coaches" page="corp-coach">
      <div className={css.base}>
        {/*<TextField*/}
        {/*  label="Search Coach"*/}
        {/*  placeholder="Start typing..."*/}
        {/*  variant={'outlined'}*/}
        {/*  value={searchText}*/}
        {/*  onChange={}*/}
        {/*/>*/}

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps: FormikProps<FormI>) => {
            return (
              <Form>
                <div className={css.filter_base}>
                  <div className={css.field_wrapper}>
                    <Field
                      component={TextField}
                      name="search"
                      label={'Search'}
                      style={{ width: '100%' }}
                      variant="outlined"
                      size={'small'}
                    />
                  </div>

                  <div className={css.field_wrapper}>
                    <Field
                      component={TextField}
                      name={'location'}
                      label={'Location'}
                      style={{ width: '100%' }}
                      variant="outlined"
                      size={'small'}
                    />
                  </div>

                  <div className={css.field_wrapper}>
                    <Field name="categories">
                      {(fieldProps: FieldProps) => {
                        const { field } = fieldProps
                        const customStyles = {
                          control: (provided: any, state: any) => {
                            return {
                              ...provided,
                              minHeight: 30,
                              '&:hover': {
                                borderColor: state.isFocused ? css.primaryColor : 'gray',
                              },
                              border: state.isFocused
                                ? `2px solid ${css.primaryColor}`
                                : '1px solid lightgray',
                              boxShadow: 'none',
                            }
                          },
                        }

                        return (
                          <Select
                            defaultValue={undefined}
                            styles={customStyles}
                            placeholder={'Select the Categories'}
                            onChange={(values: ValueType<any, any>) => {
                              formikProps.setFieldValue(field.name, values)
                            }}
                            isMulti
                            color
                            name={field.name}
                            value={field.value}
                            options={categories.map((x) => x.name)}
                            getOptionValue={(x) => x}
                            getOptionLabel={(x) => x}
                          />
                        )
                      }}
                    </Field>
                  </div>

                  <div className={css.field_wrapper}>
                    <p>Price Range</p>
                    <div className={css.filter_range}>
                      <Field
                        component={TextField}
                        name={'minPrice'}
                        label={'Min'}
                        style={{ width: '100%' }}
                        variant="outlined"
                        size={'small'}
                        type={'number'}
                      />
                      <Field
                        component={TextField}
                        name={'maxPrice'}
                        label={'Max'}
                        style={{ width: '100%' }}
                        variant="outlined"
                        size={'small'}
                        type={'number'}
                      />
                    </div>
                  </div>

                  <div className={css.field_wrapper}>
                    <p>Coach Type</p>
                    <div>
                      <Field name="coachType">
                        {(fieldProps: FieldProps) => {
                          const { field } = fieldProps
                          return (
                            <RadioGroup
                              row
                              name={field.name}
                              value={field.value}
                              onChange={(event) => {
                                formikProps.setFieldValue(field.name, event.target.value)
                              }}
                            >
                              <FormControlLabel
                                value="preferred"
                                control={<Radio />}
                                label="Preferred"
                              />
                              <FormControlLabel value="basic" control={<Radio />} label="Basic" />
                            </RadioGroup>
                          )
                        }}
                      </Field>
                    </div>
                  </div>

                  <div>
                    <Button
                      className={css.submit_button}
                      color="primary"
                      variant="contained"
                      type="submit"
                      disableElevation
                      disabled={
                        formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty
                      }
                    >
                      {formikProps.isSubmitting ? <CircularProgress size={25} /> : 'Apply'}
                    </Button>

                    <Button
                      className={css.submit_button}
                      color="primary"
                      variant="contained"
                      disableElevation
                      onClick={() => formikProps.resetForm()}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>

        <div className={css.coaches}>
          {coaches.map((item: any, index) => {
            return (
              <div key={item.id} className={css.coach_card_base}>
                <div className={css.coach_name_base}>
                  <Avatar src={item.profilePic} />
                  <div className={css.coach_name}>
                    <p>{item.displayName}</p>
                    <p>{item.location}</p>
                  </div>
                </div>

                <div className={css.coach_description}>
                  {ReactHtmlParser(decode(item.bio?.substring(0, 200)))}
                </div>

                <div className={css.coach_listings}>
                  {/*<p>{item.bio?.slice(0, 100)}...</p>*/}
                  {item?.listings.map((l: any) => {
                    // return <div className={css.listing_item} key={l.id}>{l.name}</div>
                    return (
                      <Tooltip key={l.id} title={l.name}>
                        <div className={css.coach_listing}>
                          {l?.images[0] ? (
                            <img alt={'listing'} src={l?.images[0]} />
                          ) : (
                            <img
                              className={css.coach_listing_default_img}
                              alt={'default listing'}
                              src={LogoOnlyCircle}
                            />
                          )}

                          {/*<Avatar key={l.id} title={l.name.charAt(0)} src={l?.images[0] || 'sd'} />*/}
                        </div>
                      </Tooltip>
                    )
                  })}
                </div>

                <div className={css.coach_actions}>
                  <p
                    onClick={() => addCoachButtonHandle(item.id)}
                    style={{ color: css.primaryColor1 }}
                    className={css.coach_action_btn}
                  >
                    Add
                  </p>
                  <CoachViewOfferPopup
                    trigger={
                      <p style={{ color: css.primaryColor3 }} className={css.coach_action_btn}>
                        View Offers
                      </p>
                    }
                    listings={item?.listings || []}
                    authorName={item.displayName}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </MainDashboardWrapper>
  )
}

interface MapStatePropsI {}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {}
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActionI>): MapDispatchPropsI => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(HRCoachAddPage)
