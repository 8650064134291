import { AxiosError } from 'axios'
import { Dispatch } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { backendUrl } from '../env-config'
import { authAxios } from '../helpers/axios'

// ================ Action types ================ //

export const CURRENT_USER_REQUEST = 'app/user/CURRENT_USER_REQUEST'
export const CURRENT_USER_SUCCESS = 'app/user/CURRENT_USER_SUCCESS'
export const CURRENT_USER_ERROR = 'app/user/CURRENT_USER_ERROR'

// ================ Reducer ================ //

const initialState: ReduxUserStateI = {
  currentUser: null,
  currentUserInProgress: false,
  currentUserError: null,

  currentCompany: null,
}

export default function reducer(state = initialState, action: AppActionI) {
  const { type, payload } = action
  switch (type) {
    case CURRENT_USER_REQUEST:
      return {
        ...state,
        currentUserInProgress: true,
      }

    case CURRENT_USER_SUCCESS:
      state.currentUser = payload

      const company = state.currentUser?.companies.find(
        (x: any) => x.id === state.currentUser.currentCompanyId
      )
      return {
        ...state,
        currentUserInProgress: false,

        currentCompany: company,
      }

    case CURRENT_USER_ERROR:
      return {
        ...state,
        currentUserError: payload,
        currentUserInProgress: false,
      }

    default:
      return state
  }
}

// ================ Action creators ================ //

const currentUserRequest = (): AppActionI => ({ type: CURRENT_USER_REQUEST })
const currentUserSuccess = (user: any): AppActionI => ({
  type: CURRENT_USER_SUCCESS,
  payload: user,
})
const currentUserError = (error: string): AppActionI => ({
  type: CURRENT_USER_ERROR,
  payload: error,
})

// ================ Thunks ================ //

export const fetchCurrentUser = () => async (dispatch: Dispatch, getState: () => AppStateI) => {
  dispatch(currentUserRequest())

  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(`${backendUrl}/user/current-user`, {})
      dispatch(currentUserSuccess(res.data.data))

      const company = res.data.data?.companies.find(
        (x: any) => x.id === res.data.data?.currentCompanyId
      )

      if (!company) {
        localStorage.removeItem('roles')
      }

      const companyRoles = company?.UserCompanyModel.roles
      if (companyRoles) {
        localStorage.setItem('roles', companyRoles)
      }

      resolve(res.data)
    } catch (e: any) {
      const err: AxiosError<ApiResponseI> = e
      dispatch(currentUserError(err.message))
      reject(err.response?.data.message || err.message)
    }
  })
}

export const changeCurrentCompany = (companyId: number, shouldReload: boolean = true) => async (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>,
  getState: () => AppStateI
) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(`${backendUrl}/user/change-current-company`, {
        companyId: companyId,
      })
      await dispatch(fetchCurrentUser())
      if (shouldReload) {
        window.location.reload()
      }

      resolve(res.data)
    } catch (e: any) {
      const err: AxiosError<ApiResponseI> = e
      reject(err.response?.data.message || err.message)
    }
  })
}
