import React, { useCallback, useEffect, useState } from 'react'
import css from './HRCurriculumEmployeeViewBudgetView.module.scss'
import { CellProps, Column } from 'react-table'
import moment from 'moment'
import { Tooltip } from '@material-ui/core'
import BaseTable from '../../../../components/BaseTable/BaseTable'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Minus, Plus } from 'react-feather'
import CurriculumEmployeeBudgetAllocatePopup from '../../../../components/CurriculumEmployeeBudgetAllocatePopup/CurriculumEmployeeBudgetAllocatePopup'
import {
  fetchCurriculumEmployee,
  fetchCurriculumEmployeeBudget,
} from '../HRCurriculumEmployeeViewPage.helper'

interface PropsI extends RouteComponentProps<{ id: string; empId: string; tab: string }> {}

const HRCurriculumEmployeeViewBudgetView: React.FC<PropsI> = (props) => {
  const { match } = props
  const [curriculumAttendee, setCurriculumAttendee] = useState<any>(null)
  const [budgetRecords, setBudgetRecords] = useState<any[]>([])

  const fetchCurriculumEmployeeCB = useCallback(() => {
    fetchCurriculumEmployee(match.params.id, match.params.empId)
      .then((res) => setCurriculumAttendee(res.data))
      .catch((err) => console.log(err))
  }, [match.params.empId, match.params.id])

  const fetchCurriculumEmployeeBudgetCB = useCallback(() => {
    fetchCurriculumEmployeeBudget(match.params.id, match.params.empId)
      .then((res) => setBudgetRecords(res.data))
      .catch((err) => console.log(err))
  }, [match.params.empId, match.params.id])

  const pageLoadCB = useCallback(() => {
    fetchCurriculumEmployeeCB()
    fetchCurriculumEmployeeBudgetCB()
  }, [fetchCurriculumEmployeeBudgetCB, fetchCurriculumEmployeeCB])

  useEffect(() => {
    pageLoadCB()
  }, [pageLoadCB])

  const budgetColumns: Column[] = [
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: (tableProps: any) => {
        const dateString = moment(tableProps.value).format('DD / M / YYYY')
        const fromNow = moment(tableProps.value).fromNow()
        return (
          <Tooltip title={fromNow}>
            <p>{dateString}</p>
          </Tooltip>
        )
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Remark',
      accessor: 'remark',
      Cell: (tableProps: any) => {
        if (tableProps.value) {
          return tableProps.value
        } else {
          return 'N/A'
        }
      },
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: (tableProps: CellProps<any>) => {
        let str = ''
        if (tableProps.value && tableProps.value !== 0) {
          str = '$' + tableProps?.value?.toLocaleString()
        } else {
          str = '$0'
        }
        return (
          <span style={{ color: tableProps.row.original.isAdding ? 'green' : 'red' }}>{str}</span>
        )
      },
    },

    {
      Header: 'Increase (+) / Decrease (-)',
      accessor: 'isAdding',
      Cell: (tableProps: any) => {
        return <div>{tableProps.value ? <Plus /> : <Minus />}</div>
      },
    },
  ]
  return (
    <div>
      <div className={css.details}>
        <div className={css.budget_card}>
          <p>Curriculum Budget</p>
          <p>${(curriculumAttendee?.currentBudget || 0).toLocaleString()}</p>
        </div>

        <div className={css.budget_card}>
          <p>Utilized</p>
          <p>${(curriculumAttendee?.utilized || 0).toLocaleString()}</p>
        </div>

        <div className={css.budget_card}>
          <p>Allocation</p>
          <p>
            $
            {(
              (curriculumAttendee?.utilized || 0) + (curriculumAttendee?.currentBudget || 0)
            ).toLocaleString()}
          </p>
        </div>
        <CurriculumEmployeeBudgetAllocatePopup
          trigger={<div className={css.button}>Allocate budget</div>}
          loadPageCB={pageLoadCB}
          employeeId={match.params.empId}
          curriculumId={match.params.id}
        />
      </div>
      <BaseTable columns={budgetColumns} data={budgetRecords} />
    </div>
  )
}

export default withRouter(HRCurriculumEmployeeViewBudgetView)
