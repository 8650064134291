import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import css from './HRCoachesPage.module.scss'
import { ThunkDispatch } from 'redux-thunk'
import { fetchCoachesInMyCompany, removeCoachFromCompany } from './HRCoachesPage.helper'
import { Avatar, CircularProgress } from '@material-ui/core'
import { useConfirm } from 'material-ui-confirm'
import { useAlert } from 'react-alert'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'
import { Search } from 'react-feather'
import ReactHtmlParser from 'react-html-parser'
import { decode } from 'html-entities'

interface PropsI extends MapStatePropsI, MapDispatchPropsI {}

const HRCoachesPage: React.FC<PropsI> = () => {
  const [coaches, setCoaches] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [removeLoading, setRemoveLoading] = useState<number | null>(null)
  const confirm = useConfirm()
  const alert = useAlert()

  const pageLoad = useCallback(() => {
    setLoading(true)
    fetchCoachesInMyCompany()
      .then((res) => {
        setLoading(false)
        setCoaches(res.data)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    pageLoad()
  }, [pageLoad])

  const removeCoach = (id: number) => {
    confirm().then(() => {
      setRemoveLoading(id)
      removeCoachFromCompany(id)
        .then((res) => {
          setRemoveLoading(null)
          alert.success(res.message)

          pageLoad()
        })
        .catch((err) => {
          setRemoveLoading(null)
          alert.error(err)
        })
    })
  }

  // const columns: Column[] = [
  //   {
  //     Header: 'Name',
  //     accessor: 'firstName',
  //     Cell: (props: any) => {
  //       if (!props.value) return ''
  //
  //       const img = props.row.original.profilePic
  //       return (
  //         <span className={css.employee_cell}>
  //           <Avatar src={img || ''}>{props.value.charAt(0)}</Avatar>
  //           <span>{props.value}</span>
  //         </span>
  //       )
  //     },
  //   },
  //   {
  //     Header: 'Location',
  //     accessor: 'location',
  //   },
  //   {
  //     Header: 'Email',
  //     accessor: 'email',
  //   },
  //   {
  //     Header: 'Action',
  //     accessor: 'action',
  //     Cell: (tableProps: any) => (
  //       <TableActions
  //         actions={[
  //           {
  //             icon: faTrashAlt,
  //             action: () => removeCoach(tableProps.row.original.id),
  //             tooltip: 'Remove coach from the company',
  //             isLoading: tableProps.row.original.id === removeLoading,
  //           },
  //         ]}
  //       />
  //     ),
  //   },
  // ]

  // console.log(coaches.length)
  return (
    <MainDashboardWrapper title="Coaches" page="corp-coach">
      <div className={css.base}>
        <div className={css.actions}>
          {/*<InviteCoachPopup*/}
          {/*  trigger={() => (*/}
          {/*    <div className={css.roundButton}>*/}
          {/*      <FontAwesomeIcon icon={faUserPlus} />*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*/>*/}
          <PrimaryButton
            to="/hr/coach/add"
            name="Find More"
            icon={Search}
            style={{ marginLeft: 10 }}
          />
        </div>

        {!loading && coaches.length < 1 && (
          <p>No coaches are added, Click "Find More" button to add coaches</p>
        )}
        {loading && <CircularProgress />}
        <div className={css.items}>
          {coaches.map((item) => {
            return (
              <div key={item.id} className={css.coach_card_base}>
                <div className={css.coach_name_base}>
                  <Avatar src={item.profilePic} />
                  <div className={css.coach_name}>
                    <p>{item.displayName}</p>
                    <p>{item.location}</p>
                  </div>
                </div>

                <div className={css.coach_description}>
                  {ReactHtmlParser(decode(item.bio?.substring(0, 200)))}
                </div>

                <div className={css.coach_actions}>
                  {removeLoading === item.id ? (
                    <CircularProgress size={20} />
                  ) : (
                    <p className={css.coach_remove_btn} onClick={() => removeCoach(item.id)}>
                      Remove
                    </p>
                  )}
                </div>
              </div>
            )
          })}
        </div>
        {/*<BaseTable columns={columns} data={coaches} loading={loading} />*/}
      </div>
    </MainDashboardWrapper>
  )
}

interface MapStatePropsI {}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {}
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActionI>): MapDispatchPropsI => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(HRCoachesPage)
