import { applyMiddleware, combineReducers, createStore, Store } from 'redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import AuthReducer from '../ducks/auth.duck'
import UserReducer from '../ducks/user.duck'
import FormUtilsReducer from '../ducks/formUtils.duck'
import EmployeeReducer from '../ducks/employee.duck'

export const rootReducer = combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  formUtils: FormUtilsReducer,
  employee: EmployeeReducer,
})

export default function configureStore(): Store<AppStateI, AppActionI> {
  return createStore(rootReducer, applyMiddleware(thunk as ThunkMiddleware<AppStateI, AppActionI>))
}
