import React from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import css from './CoachDashboardWrapper.module.scss'
import { Divider, List, ListItem, ListItemText } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { changeCurrentCompany } from '../../ducks/user.duck'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps {
  page: 'my-orders'
  title: string
}

const CoachDashboardWrapper: React.FC<PropsI> = (props) => {
  const { title, page } = props
  let { children } = props

  // const [selectedCompanyId, setSelectedCompanyId] = useState(0)

  // if (page === 'company') {
  //   children = React.Children.map(children, (child) => {
  //     if (React.isValidElement(child)) {
  //       return React.cloneElement(child, { companyId })
  //     }
  //     return child
  //   })
  // }

  return (
    <>
      <p className={css.title}>{title}</p>
      <Divider className={css.divider} />
      <div className={css.base}>
        <div className={css.list}>
          <List>
            <Link to="/coach/my-orders" className={css.link}>
              <ListItem
                classes={{ selected: css.listItemSelected, root: css.listItemRoot }}
                button
                selected={page === 'my-orders'}
              >
                <FontAwesomeIcon className={css.listIcon} icon={faStar} />
                <ListItemText primary="My Orders" />
              </ListItem>
            </Link>
          </List>
        </div>

        <div className={css.content}>{children}</div>
      </div>
    </>
  )
}

interface MapStatePropsI {
  currentUser: any
  currentUserInProgress: boolean
}

interface MapDispatchPropsI {
  changeCurrentCompanyA: (companyId: number) => Promise<ApiResponseI>
}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {
    currentUser: state.user.currentUser,
    currentUserInProgress: state.user.currentUserInProgress,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>
): MapDispatchPropsI => {
  return {
    changeCurrentCompanyA: (companyId: number) => dispatch(changeCurrentCompany(companyId)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CoachDashboardWrapper))
