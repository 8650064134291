import React, { useEffect, useState } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { Column } from 'react-table'
import BaseTable from '../../components/BaseTable/BaseTable'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import { fetchMyCompanies } from './MyCompaniesPage.helper'
import TableActions from '../../components/TableActions/TableActions'
import { faArrowRight, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { AvatarGroup } from '@material-ui/lab'
import { Avatar, Button, Tooltip } from '@material-ui/core'
import { ThunkDispatch } from 'redux-thunk'
import { changeCurrentCompany } from '../../ducks/user.duck'
import { connect } from 'react-redux'
import NavBar from '../../components/NavBar/NavBar'
import css from './MyCompaniesPage.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface PropsI extends RouteComponentProps, MapStatePropsI, MapDispatchPropsI {}

const MyCompaniesPage: React.FC<PropsI> = (props) => {
  const { changeCurrentCompanyA, history } = props
  const [companies, setCompanies] = useState([])
  useEffect(() => {
    fetchMyCompanies().then((res) => {
      setCompanies(res.data)
    })
  }, [])

  const changeCompanyHandle = (compId: number) => {
    changeCurrentCompanyA(compId, false).then(() => {
      history.push('/')
    })
  }

  const columns: Column[] = [
    {
      Header: 'Company',
      accessor: 'name',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Roles',
      accessor: 'UserCompanyModel.roles',
      disableSortBy: true,
      Cell: (tableProps: any) => {
        return (
          <AvatarGroup max={5}>
            {tableProps.value.map((item: any, index: number) => (
              <Tooltip key={index} title={`${item}`}>
                <Avatar sizes="10px" alt={`${item}`}>
                  {item.charAt(0).toUpperCase()}
                </Avatar>
              </Tooltip>
            ))}
          </AvatarGroup>
        )
      },
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (tableProps: any) => (
        <TableActions
          actions={[
            {
              icon: faArrowRight,
              action: () => changeCompanyHandle(tableProps.row.original.id),
              tooltip: 'Switch to this company',
            },
          ]}
        />
      ),
      disableSortBy: true,
    },
  ]
  return (
    <PageWrapper>
      {/*<RoundButton to="/my-companies/create" icon={faPlus} />*/}
      <NavBar />
      <div className={css.base}>
        {/*<Link className={css.enroll_button} to="/my-companies/create">*/}
        {/*  <Button*/}
        {/*    variant="outlined"*/}
        {/*    color="primary"*/}
        {/*    startIcon={<FontAwesomeIcon icon={faPlusSquare} />}*/}
        {/*  >*/}
        {/*    Create a company*/}
        {/*  </Button>*/}
        {/*</Link>*/}

        <BaseTable columns={columns} data={companies} />
      </div>
    </PageWrapper>
  )
}

interface MapStatePropsI {}

interface MapDispatchPropsI {
  changeCurrentCompanyA: (companyId: number, shouldReload?: boolean) => Promise<ApiResponseI>
}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {}
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActionI>): MapDispatchPropsI => {
  return {
    changeCurrentCompanyA: (companyId: number, shouldReload?: boolean) =>
      dispatch(changeCurrentCompany(companyId, shouldReload)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyCompaniesPage))
