import axios, { AxiosError } from 'axios'
import { backendUrl } from '../env-config'
// import jwt from 'jsonwebtoken'

export const isAuthenticated = (): boolean => {
  const accessToken = localStorage.getItem('refresh_token')

  return !!accessToken
}

export const getCompanyRoles = (): string[] => {
  const roles = localStorage.getItem('roles')

  return roles?.split(',') || []
}

export const refreshAccessToken = async (refreshToken: string) => {
  console.log('refreshing token ', refreshToken)
  return new Promise<string>(async (resolve, reject) => {
    try {
      const defaultAxios = axios.create()
      const res = await defaultAxios.post<ApiResponseI>(
        `${backendUrl}/auth/refresh-access-token`,
        {
          refreshToken,
        },
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      )

      localStorage.setItem('access_token', res.data.data)

      resolve(res.data.data)
    } catch (e: any) {
      const err: AxiosError<ApiResponseI> = e

      localStorage.removeItem('refresh_token')

      reject(err.response?.data.message || err.message)
    }
  })
}
