import { authAxios } from './axios'
import { backendUrl } from '../env-config'

export const fetchAllCategories = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.get<ApiResponseI>(`${backendUrl}/form-utils/categories`)
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchUserBySearchText = async (searchText: string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(
        `${backendUrl}/form-utils/fetch-users-by-search-text`,
        { searchText }
      )
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchListingByText = async (searchText: string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(
        `${backendUrl}/form-utils/fetch-listing-by-search-text`,
        { searchText }
      )
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchEmployeesInCompany = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.get<ApiResponseI>(`${backendUrl}/hr/employee/`)
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}
