import React from 'react'
import css from './CurriculumEmployeeBudgetAllocatePopup.module.scss'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import SmallRoundColoseButton from '../SmallRoundCloseButton/SmallRoundCloseButton'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { Button, CircularProgress, MenuItem, Select } from '@material-ui/core'
import * as yup from 'yup'
import { FormikHelpers } from 'formik/dist/types'
import { TextField } from 'formik-material-ui'
import NumberFormatInputField from '../NumberFormatInputField/NumberFormatInputField'
import { useAlert } from 'react-alert'
import { allocateCurriculumAttendeeBudget } from '../../containers/HR/HRCurriculumEmployeeViewPage/HRCurriculumEmployeeViewPage.helper'
import { useConfirm } from 'material-ui-confirm'

interface PropsI extends RouteComponentProps {
  trigger: JSX.Element
  employeeId: string
  curriculumId: string
  loadPageCB: () => void
}

interface FormFieldsI {
  type: string
  remark: string
  amount: number | string
}

const CurriculumEmployeeBudgetAllocatePopup: React.FC<PropsI> = (props) => {
  const { trigger, employeeId, curriculumId, loadPageCB } = props

  const alert = useAlert()

  const validationSchema = yup.object({
    type: yup.string().label('Type').required(),
    remark: yup.string().label('Remark'),
    amount: yup.number().label('Amount').required(),
  })

  const initialValues: FormFieldsI = { type: 'increase', remark: '', amount: '' }

  const confirm = useConfirm()
  const onSubmit = (
    values: FormFieldsI,
    formikHelpers: FormikHelpers<FormFieldsI>,
    close: () => void
  ) => {
    confirm()
      .then(() => {
        allocateCurriculumAttendeeBudget(
          curriculumId,
          employeeId,
          values.amount,
          values.remark,
          values.type
        )
          .then(() => {
            alert.success('Success')
            formikHelpers.setSubmitting(false)
            close()
            loadPageCB()
          })
          .catch((err) => {
            alert.error(err)
            formikHelpers.setSubmitting(false)
            close()
          })
      })
      .catch(() => {
        formikHelpers.setSubmitting(false)
      })
  }

  return (
    <Popup trigger={trigger} modal closeOnDocumentClick={false}>
      {(close: any) => (
        <div className={css.base}>
          <SmallRoundColoseButton onClick={close} className={css.close_button} />
          <p className={css.title}>Budget allocation</p>
          <p>You can increase or decrease this budget. Enter the new budget below.</p>

          <Formik
            initialValues={initialValues}
            onSubmit={(...args) => onSubmit(...args, close)}
            validationSchema={validationSchema}
          >
            {(formikProps: FormikProps<FormFieldsI>) => {
              return (
                <Form className={css.form}>
                  <Field name="type">
                    {(fieldProps: FieldProps) => {
                      const { field } = fieldProps
                      return (
                        <Select
                          variant="outlined"
                          value={field.value}
                          onChange={(event) => {
                            formikProps.setFieldValue(field.name, event.target.value)
                            // console.log(event)
                          }}
                        >
                          <MenuItem value="increase">Increase</MenuItem>
                          <MenuItem value="decrease">Decrease</MenuItem>
                        </Select>
                      )
                    }}
                  </Field>

                  <Field
                    className={css.input_field}
                    component={TextField}
                    name="amount"
                    variant="outlined"
                    label="Amount"
                    color="primary"
                    required
                    InputProps={{
                      inputComponent: NumberFormatInputField,
                    }}
                  />

                  <Field
                    className={css.input_field}
                    component={TextField}
                    name="remark"
                    variant="outlined"
                    label="Remark"
                    color="primary"
                  />

                  <Button
                    className={css.submit_button}
                    color="primary"
                    variant="contained"
                    type="submit"
                    disableElevation
                    disabled={
                      formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty
                    }
                  >
                    {formikProps.isSubmitting ? <CircularProgress size={25} /> : 'Save'}
                  </Button>
                </Form>
              )
            }}
          </Formik>
        </div>
      )}
    </Popup>
  )
}

export default withRouter(CurriculumEmployeeBudgetAllocatePopup)
