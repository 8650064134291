import React, { useCallback, useEffect, useState } from 'react'
import css from './BillingCompanyBudgetView.module.scss'
import CompanyBudgetAllocatePopup from '../../../../components/CompanyBudgetAllocatePopup/CompanyBudgetAllocatePopup'
import { fetchCompanyBudgetRecords } from '../../HRSettingPage/HRSettingsPage.helper'
import { fetchCompanyDetails } from '../HRBillingPage.helper'
import { CellProps, Column } from 'react-table'
import moment from 'moment'
import { Tooltip } from '@material-ui/core'
import BaseTable from '../../../../components/BaseTable/BaseTable'
import { Minus, Plus } from 'react-feather'

interface PropsI {}

const BillingCompanyBudgetView: React.FC<PropsI> = (props) => {
  const [budgetDetails, setBudgetDetails] = useState<any[]>([])
  const [companyDetails, setCompanyDetails] = useState<any>()

  const getBudgetRecords = useCallback(() => {
    fetchCompanyBudgetRecords()
      .then((res) => {
        setBudgetDetails(res.data)
      })
      .catch((err) => console.log(err))
  }, [])

  const getCompanyData = useCallback(() => {
    fetchCompanyDetails()
      .then((res) => {
        setCompanyDetails(res.data)
      })
      .catch((err) => console.log(err))
  }, [])

  const loadPageCB = useCallback(() => {
    getBudgetRecords()
    getCompanyData()
  }, [getBudgetRecords, getCompanyData])

  useEffect(() => {
    loadPageCB()
  }, [loadPageCB])

  const budgetColumns: Column[] = [
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: (tableProps: any) => {
        const dateString = moment(tableProps.value).format('DD / M / YYYY')
        const fromNow = moment(tableProps.value).fromNow()
        return (
          <Tooltip title={fromNow}>
            <p>{dateString}</p>
          </Tooltip>
        )
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Remark',
      accessor: 'remark',
      Cell: (tableProps: any) => {
        if (tableProps.value) {
          return tableProps.value
        } else {
          return 'N/A'
        }
      },
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: (tableProps: CellProps<any>) => {
        let str = ''
        if (tableProps.value && tableProps.value !== 0) {
          str = '$' + tableProps?.value?.toLocaleString()
        } else {
          str = '$0'
        }
        return (
          <span style={{ color: tableProps.row.original.isAdding ? 'green' : 'red' }}>{str}</span>
        )
      },
    },

    {
      Header: 'Increase (+) / Decrease (-)',
      accessor: 'isAdding',
      Cell: (tableProps: any) => {
        return <div>{tableProps.value ? <Plus /> : <Minus />}</div>
      },
    },
  ]

  const currentBudget = companyDetails?.currentBudget ? companyDetails?.currentBudget : 0

  const utilized = companyDetails?.utilized ? companyDetails?.utilized : 0
  return (
    <div>
      <div className={css.details}>
        <div className={css.budget_card}>
          <p>Company Budget</p>
          <p>${currentBudget.toLocaleString()}</p>
        </div>

        <div className={css.budget_card}>
          <p>Utilized</p>
          <p>${utilized.toLocaleString()}</p>
        </div>

        <div className={css.budget_card}>
          <p>Total Allocation</p>
          <p>${(currentBudget + utilized).toLocaleString()}</p>
        </div>

        <div>
          <CompanyBudgetAllocatePopup
            trigger={<div className={css.button}>Allocate Funds</div>}
            loadPageCB={loadPageCB}
          />
        </div>
      </div>

      <BaseTable columns={budgetColumns} data={budgetDetails} />
    </div>
  )
}

export default BillingCompanyBudgetView
