import React from 'react'

import { Button, ButtonProps, makeStyles } from '@material-ui/core'

const useButtonStyles = makeStyles({
  root: {
    borderRadius: '50px',
    color: 'white',
    margin: '20px 0',
  },
})

interface PropsI extends ButtonProps {}

const StyledMuiButton: React.FC<PropsI> = (props) => {
  const { children, ...rest } = props
  const buttonClasses = useButtonStyles()
  return (
    <Button classes={buttonClasses} {...rest}>
      {children}
    </Button>
  )
}

export default StyledMuiButton
