import { authAxios } from '../../helpers/axios'
import { backendUrl } from '../../env-config'
import axios from 'axios'

export const invitationDetails = async (token: string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await axios.post(`${backendUrl}/hr/employee/invitation-details`, {
        token: token,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const verifyInvitation = async (token: string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/employee/invitation-verify`, {
        token: token,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}
