import { authAxios } from '../../../helpers/axios'
import { backendUrl } from '../../../env-config'

export const fetchCurriculumDetails = async (curriculumId: string | number) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/curriculum/get-curriculum-details`, {
        curriculumId,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const allocateCurriculumEmployeeBudget = async (
  values: any,
  curriculumId: string | number
) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(
        `${backendUrl}/curriculum/allocate-bulk-curriculum-employee-budget`,
        {
          values,
          curriculumId,
        }
      )
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}
