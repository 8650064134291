import { authAxios } from '../../../helpers/axios'
import { backendUrl } from '../../../env-config'

export const fetchListingsFromMyCoaches = async (searchText: string, curriculumId: string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(
        `${backendUrl}/curriculum/get-listings-from-selected-coaches`,
        { searchText, curriculumId }
      )
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const addListingToCurriculum = async (listingId: number, curriculumId: number) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(`${backendUrl}/curriculum/add-listing`, {
        listingId,
        curriculumId,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchListingsInACurriculum = async (curriculumId: string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(
        `${backendUrl}/curriculum/get-selected-listings`,
        {
          curriculumId,
        }
      )
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const removeListingFromCurriculum = async (listingId: number, curriculumId: number) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(`${backendUrl}/curriculum/remove-listing`, {
        listingId,
        curriculumId,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchCoachesForCurriculum = async (curriculumId: number | string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(
        `${backendUrl}/curriculum/get-coaches-for-curriculum`,
        {
          curriculumId,
        }
      )
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}
