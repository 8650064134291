import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import css from './EmployeeCompanyMyBudgetView.module.scss'
import { fetchMyBudgetData } from '../EmployeeCompanyPage.helper'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps {}

const EmployeeCompanyMyBudgetView: React.FC<PropsI> = (props) => {
  const [budgetData, setBudgetData] = useState<any>({})
  useEffect(() => {
    fetchMyBudgetData()
      .then((res) => {
        setBudgetData(res.data)
      })
      .catch((err) => {})
  }, [])

  return (
    <div className={css.base}>
      {/*<Card className={css.card}>*/}
      {/*  <CardHeader title="Budget" />*/}
      {/*  <CardContent>*/}
      {/*    <h2 style={{ textAlign: 'center' }}>*/}
      {/*      ${budgetData?.totalInitialBudget?.toLocaleString()}*/}
      {/*    </h2>*/}
      {/*  </CardContent>*/}
      {/*</Card>*/}
      {/*<Card className={css.card}>*/}
      {/*  <CardHeader title="Utilized" />*/}
      {/*  <CardContent>*/}
      {/*    <h2 style={{ textAlign: 'center' }}>${budgetData?.totalUtilized?.toLocaleString()}</h2>*/}
      {/*  </CardContent>*/}
      {/*</Card>*/}
      {/*<Card className={css.card}>*/}
      {/*  <CardHeader title="Remaining" />*/}
      {/*  <CardContent>*/}
      {/*    <h2 style={{ textAlign: 'center' }}>${budgetData?.totalRemaining?.toLocaleString()}</h2>*/}
      {/*  </CardContent>*/}
      {/*</Card>*/}
      <div className={css.budget_items}>
        <div className={css.budget_card}>
          <p>Remaining company discretionary Budget</p>
          <p>${(budgetData?.company?.discretionaryBudget || 0).toLocaleString()}</p>
        </div>
        <div className={css.budget_card}>
          <p>Company discretionary utilized</p>
          <p>${(budgetData?.company?.discretionaryUtilized || 0).toLocaleString()}</p>
        </div>

        <div className={css.budget_card}>
          <p>Remaining company required Budget</p>
          <p>${(budgetData?.company?.requiredBudget || 0).toLocaleString()}</p>
        </div>
        <div className={css.budget_card}>
          <p>Company required utilized</p>
          <p>${(budgetData?.company?.requiredUtilized || 0).toLocaleString()}</p>
        </div>
      </div>

      <p className={css.curriculum_budget_title}>Curriculum budget details</p>
      <div className={css.curriculum_budget_items}>
        {budgetData?.curriculums?.map((item: any, index: number) => {
          return (
            <div key={index} className={css.curriculum_card}>
              <p>Curriculum: {item?.curriculum?.name}</p>
              <p>
                Total Allocation: $
                {((item.currentBudget || 0) + (item.utilized || 0)).toLocaleString()}
              </p>

              <p>Utilized: ${(item.utilized || 0).toLocaleString()}</p>
              <p>Remaining Budget: ${(item.currentBudget || 0).toLocaleString()}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

interface MapStatePropsI {
  // currentCompany: any
}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {
    // currentCompany: state.user.currentCompany,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>
): MapDispatchPropsI => {
  return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmployeeCompanyMyBudgetView))
