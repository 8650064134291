import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import { isAuthenticated } from '../../helpers/authentication'
import { invitationDetails, verifyInvitation } from './InvitationVerifyPage.helper'
import { useAlert } from 'react-alert'
import { CircularProgress } from '@material-ui/core'
import css from './InvitationVerifyPage.module.scss'

interface PropsI
  extends MapStatePropsI,
    MapDispatchPropsI,
    RouteComponentProps<{ token: string }> {}

const InvitationVerifyPage: React.FC<PropsI> = (props) => {
  const { match, history } = props
  const { token } = match.params

  const alert = useAlert()

  const init = useCallback(async () => {
    try {
      const authenticated = isAuthenticated()
      if (token) {
        const tokenDetailsRes = await invitationDetails(token)

        const tokenDetails = tokenDetailsRes?.data
        if (!tokenDetails) return
        if (!authenticated) {
          if (tokenDetails.isNew) {
            history.push('/auth/signup', {
              email: tokenDetails.email,
              from: window.location.pathname,
            })
          } else {
            history.push('/auth/login', {
              email: tokenDetails.email,
              from: window.location.pathname,
            })
          }
        } else {
          verifyInvitation(token)
            .then(() => {
              history.push('/')
            })
            .catch((err) => {
              alert.error(err)
              history.push('/')
            })
        }
      }
    } catch (e: any) {
      alert.error(e)
      history.push('/')
    }
  }, [alert, history, token])

  useEffect(() => {
    init()
  }, [init])

  return (
    <PageWrapper>
      <div className={css.base}>
        <CircularProgress size={50} />
      </div>
    </PageWrapper>
  )
}

interface MapStatePropsI {}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {}
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>
): MapDispatchPropsI => {
  return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvitationVerifyPage))
