import React from 'react'
import NumberFormat from 'react-number-format'

const NumberFormatInputField: React.FC = (props: any) => {
  const { inputRef, onChange, ...rest } = props
  return (
    // @ts-ignore
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        })
      }}
      decimalScale={2}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  )
}

export default NumberFormatInputField
