import { createMuiTheme } from '@material-ui/core'
import styles from './scss/import-from-ts.module.scss'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: styles.primaryColor,
    },
    secondary: {
      main: styles.primaryColor,
    },
  },
})
