import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import BaseTable from '../../../../components/BaseTable/BaseTable'
import css from './EmployeeCompanyMyCurriculumsView.module.scss'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import CurriculumEnrollPopup from '../../../../components/CurriculumEnrollPopup/CurriculumEnrollPopup'
import { fetchMyCurriculums, fetchMyEnrolledCurriculums } from '../EmployeeCompanyPage.helper'
import TableActions from '../../../../components/TableActions/TableActions'
import { vivekaUrl } from '../../../../env-config'
import CurrCard from './curr-card/CurrCard'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps {}

const EmployeeCompanyMyCurriculumsView: React.FC<PropsI> = (props) => {
  const [enrollableCurriculums, setEnrollableCurriculums] = useState<any>([])

  useEffect(() => {
    // fetchMyEnrolledCurriculums().then((res) => {
    //   setEnrolledCurriculums(res.data)
    // })

    fetchMyCurriculums().then((res) => {
      setEnrollableCurriculums(res.data)
    })
  }, [])

  // const columns: any[] = [
  //   {
  //     Header: 'Curriculum',
  //     accessor: 'curriculum',
  //     Cell: (tableProps: any) => {
  //       return <div>{tableProps.value.name}</div>
  //     },
  //   },
  //   {
  //     Header: 'Type',
  //     accessor: 'curriculum.type',
  //     Cell: (tableProps: any) => {
  //       return tableProps?.value?.toUpperCase() || ''
  //     },
  //   },
  //   {
  //     Header: 'Listing',
  //     accessor: 'listing',
  //     Cell: (tableProps: any) => {
  //       return <div>{tableProps.value.name}</div>
  //     },
  //   },
  //   {
  //     Header: 'Coach',
  //     accessor: 'coach',
  //     Cell: (tableProps: any) => {
  //       return <div>{tableProps?.value?.displayName}</div>
  //     },
  //   },
  //   {
  //     Header: 'Utilized',
  //     accessor: 'price',
  //     Cell: (tableProps: any) => {
  //       return tableProps.value
  //         ? `$${tableProps.value.toLocaleString()}`
  {
    /*        : `$${(0).toLocaleString()}`*/
  }
  {
    /*    },*/
  }
  {
    /*  },*/
  }
  {
    /*  // {*/
  }
  {
    /*  //   Header: 'Status',*/
  }
  {
    /*  //   accessor: 'status',*/
  }
  //   // },
  //   // {
  //   //   Header: 'Is Complete',
  //   //   accessor: 'isComplete',
  //   //   Cell: (tableProps: any) => {
  //   //     return <div>{tableProps.value ? <FontAwesomeIcon icon={faCheck} /> : 'No'}</div>
  {
    /*  //   },*/
  }
  //   // },
  //   {
  //     Header: 'Actions',
  //     accessor: 'coachActivityId',
  //     Cell: (tableProps: any) => (
  //       <TableActions
  //         actions={[
  //           {
  //             icon: faEye,
  //             action: () =>
  //               window.open(
  //                 `${vivekaUrl}/v-coach/client/my-activities/${tableProps.value}`,
  //                 '_self'
  //               ),
  //             tooltip: 'View',
  //           },
  //         ]}
  //       />
  //     ),
  //   },
  // ]

  console.log(enrollableCurriculums)

  return (
    <div className={css.base}>
      {/*<CurriculumEnrollPopup*/}
      {/*  trigger={() => (*/}
      {/*    // <Button*/}
      {/*    //   className={css.enroll_button}*/}
      {/*    //   variant="outlined"*/}
      {/*    //   color="primary"*/}
      {/*    //   startIcon={<FontAwesomeIcon icon={faPlusSquare} />}*/}
      {/*    // >*/}
      {/*    //   Enroll in a Curriculum*/}
      {/*    // </Button>*/}
      {/*    <div className={css.button}>Enroll in a Curriculum</div>*/}
      {/*  )}*/}
      {/*/>*/}

      {/*<p>My activities</p>*/}

      {/*<BaseTable columns={columns} data={enrollableCurriculums} />*/}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {enrollableCurriculums?.map((x: any) => {
          return (
            <CurrCard
              title={x.name}
              type={x.type}
              description={x.description}
              remaining={x?.CurriculumAttendeeModel?.currentBudget}
              url={`/employee/company/enroll/${x.id}`}
              categories={x.categories}
            />
          )
        })}
      </div>
    </div>
  )
}

interface MapStatePropsI {
  // currentCompany: any
}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {
    // currentCompany: state.user.currentCompany,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>
): MapDispatchPropsI => {
  return {}
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EmployeeCompanyMyCurriculumsView)
)
