import { AxiosError } from 'axios'
import { Dispatch } from 'redux'
import { backendUrl } from '../env-config'
import { authAxios } from '../helpers/axios'

// ================ Action types ================ //

export const EMPLOYEES_FETCH_REQUEST = 'app/employee/EMPLOYEES_FETCH_REQUEST'
export const EMPLOYEES_FETCH_COMPLETE = 'app/employee/EMPLOYEES_FETCH_COMPLETE'

// ================ Reducer ================ //

const initialState: ReduxEmployeeStateI = {
  employees: [],
  employeeFetchInProgress: false,
}

export default function reducer(state = initialState, action: AppActionI) {
  const { type, payload } = action
  switch (type) {
    case EMPLOYEES_FETCH_REQUEST:
      return {
        ...state,
        employees: [],
        employeeFetchInProgress: true,
      }

    case EMPLOYEES_FETCH_COMPLETE:
      return {
        ...state,
        employees: payload || [],
        employeeFetchInProgress: false,
      }

    default:
      return state
  }
}

// ================ Action creators ================ //

const employeeFetchRequest = (): AppActionI => ({ type: EMPLOYEES_FETCH_REQUEST })
const employeeFetchComplete = (employees?: any[]): AppActionI => ({
  type: EMPLOYEES_FETCH_COMPLETE,
  payload: employees,
})

// ================ Thunks ================ //

export const fetchEmployees = () => async (dispatch: Dispatch, getState: () => AppStateI) => {
  dispatch(employeeFetchRequest())

  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.get<ApiResponseI>(`${backendUrl}/hr/employee/`)
      dispatch(employeeFetchComplete(res.data.data.employees))
      resolve(res.data)
    } catch (e: any) {
      dispatch(employeeFetchComplete())
      const err: AxiosError<ApiResponseI> = e
      reject(err.response?.data.message || err.message)
    }
  })
}

export const addUserToCompany = (values: HREmployeeFormValuesI) => async (
  dispatch: Dispatch,
  getState: () => AppStateI
) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post<ApiResponseI>(`${backendUrl}/employee/add-user-to-company`, {
        values,
      })
      resolve(res.data)
    } catch (e: any) {
      const err: AxiosError<ApiResponseI> = e
      reject(err.response?.data.message || err.message)
    }
  })
}
