import React, { useEffect, useState } from 'react'
import css from './AuthPage.module.scss'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import LoginTab from './LoginTab/LoginTab'
import SignupTab from './SignupTab/SignupTab'
import PageWrapper from '../../components/PageWrapper/PageWrapper'
import { isAuthenticated } from '../../helpers/authentication'

interface PropsI
  extends RouteComponentProps<{ tab: string }, any, { from: string; email: string }> {}

const TABS = {
  LOGIN: 'login',
  SIGNUP: 'signup',
}

const AuthPage: React.FC<PropsI> = (props) => {
  const { match, history, location } = props
  const { tab } = match.params
  const [selectedTab, setSelectedTab] = useState<string>(TABS.LOGIN)

  useEffect(() => {
    setSelectedTab(tab)
  }, [tab])

  useEffect(() => {
    isAuthenticated() && history.push('/')
  }, [history])

  const tabChangeHandle = (event: React.ChangeEvent<{}>, value: any) => {
    history.push(`/auth/${value}`, location.state)
  }

  return (
    <PageWrapper>
      <div className={css.base}>
        <TabContext value={selectedTab}>
          <TabList
            indicatorColor="primary"
            textColor="primary"
            onChange={tabChangeHandle}
            centered
            className={css.tab_list}
          >
            <Tab label="Login" value={TABS.LOGIN} />
            <Tab label="Signup" value={TABS.SIGNUP} />
          </TabList>
          <TabPanel value={TABS.LOGIN}>
            <LoginTab />
          </TabPanel>
          <TabPanel value={TABS.SIGNUP}>
            <SignupTab />
          </TabPanel>
        </TabContext>
      </div>
    </PageWrapper>
  )
}

export default withRouter(AuthPage)
