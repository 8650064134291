import React, { Component } from 'react'
import { connect } from 'react-redux'
import css from './EditHRCurriculumPage.module.scss'
import { ThunkDispatch } from 'redux-thunk'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import HRCurriculumForm from '../../../forms/HRCurriculumForm/HRCurriculumForm'
import { FormikHelpers } from 'formik/dist/types'
import { AlertManager, withAlert } from 'react-alert'
import {
  createOrUpdateCurriculum,
  fetchCurriculum,
  getMyCompany,
} from './EditHRCurriculumPage.helper'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import { Avatar, CircularProgress } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser'
import { decode } from 'html-entities'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps<{ id?: string }> {
  alert: AlertManager
}

interface StatesI {
  isNew: boolean
  curriculum: CurriculumModelI | null
  company: any | null
}

class EditHRCurriculumPage extends Component<PropsI, StatesI> {
  constructor(props: PropsI) {
    super(props)

    this.state = {
      isNew: this.isNew(),
      curriculum: null,
      company: null,
    }

    this.onSubmitForm = this.onSubmitForm.bind(this)
    this.isNew = this.isNew.bind(this)
  }

  isNew() {
    const { match } = this.props
    return !match.params.id
  }

  componentDidMount() {
    const { match, history } = this.props

    if (!this.isNew()) {
      fetchCurriculum(match.params.id)
        .then((res) => this.setState({ curriculum: res.data }))
        .catch(() => {
          history.push('/hr/curriculum')
        })
    }

    getMyCompany()
      .then((res) => {
        this.setState({
          company: res.data,
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onSubmitForm(
    values: HRCurriculumFormValuesI,
    formikHelpers: FormikHelpers<HRCurriculumFormValuesI>
  ) {
    const { alert, match, history } = this.props

    if (!this.isNew()) {
      values.id = match.params.id
    }

    createOrUpdateCurriculum(values)
      .then((res) => {
        alert.success(res.message)
        formikHelpers.setSubmitting(false)
        history.push(`/hr/curriculum/edit/${res.data.id}/listings`)
      })
      .catch((err) => {
        alert.error(err)
        formikHelpers.setSubmitting(false)
      })
  }

  render() {
    const { curriculum } = this.state

    const initialValues = {
      name: curriculum?.name || '',
      categories: curriculum?.categories.map((x) => ({ id: x.id, name: x.name })) || [],
      description: curriculum?.description || '',
      budget: curriculum?.currentBudget || '',
      minPrice: curriculum?.minPrice || '',
      maxPrice: curriculum?.maxPrice || '',
      type: curriculum?.type || '',
      // attendees:
      //   curriculum?.attendees.map((x) => ({
      //     id: x.id,
      //     budget: x.CurriculumAttendeeModel.currentBudget,
      //   })) || [],
      // listings: curriculum?.listings.map((x) => x.id) || [],
      // attendeeBudgets: [],
      // people: [],
    }

    const isFormVisible = (!this.isNew() && !!curriculum) || this.isNew()

    // const additionalAttendeeData = curriculum?.attendees.map((x) => {
    //   return {
    //     id: x.id,
    //     currentBudget: x.CurriculumAttendeeModel.currentBudget,
    //     initialBudget: x.CurriculumAttendeeModel.initialBudget,
    //     utilized: x.CurriculumAttendeeModel.utilized,
    //   }
    // })

    return (
      <MainDashboardWrapper title="Edit Curriculum" page="corp-curriculum">
        <div style={{ display: 'flex', gap: '50px' }}>
          <HRCurriculumForm
            onSubmit={this.onSubmitForm}
            initialValues={initialValues}
            isVisible={isFormVisible}
            // initialSelectedListings={curriculum?.listings || []}
            additionalData={{
              // attendees: additionalAttendeeData,
              company: this.state.company,
            }}
            isNew={this.isNew()}
          />
          <div className={css.r_coaches}>
            <p>Rcomended Coaches</p>
            {this.state.company?.coaches.map((item: any) => {
              return (
                <div key={item.id} className={css.coach_card_base}>
                  <div className={css.coach_name_base}>
                    <Avatar src={item.profilePic} />
                    <div className={css.coach_name}>
                      <p>{item.displayName}</p>
                      <p>{item.location}</p>
                    </div>
                  </div>

                  <div className={css.coach_description}>
                    {ReactHtmlParser(decode(item.bio?.substring(0, 200)))}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </MainDashboardWrapper>
    )
  }
}

interface MapStatePropsI {}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {}
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>
): MapDispatchPropsI => {
  return {}
}

export default withAlert()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(EditHRCurriculumPage))
)
