import React, { useCallback, useEffect, useState } from 'react'
import { CellProps, Column } from 'react-table'
import moment from 'moment'
import { Tooltip } from '@material-ui/core'
import BaseTable from '../../../../components/BaseTable/BaseTable'
import { fetchCompanyBudgetRequest } from '../HRBillingPage.helper'

interface PropsI {}

const BillingCompanyBudgetRequestView: React.FC<PropsI> = (props) => {
  const [budgetRequests, setBudgetRequests] = useState<any[]>([])

  const loadPageCB = useCallback(() => {
    fetchCompanyBudgetRequest().then((res) => {
      setBudgetRequests(res.data)
    })
  }, [])

  useEffect(() => {
    loadPageCB()
  }, [loadPageCB])

  const columns: any[] = [
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (cellProps: CellProps<any>) => {
        let textColor = ''
        switch (cellProps.value) {
          case 'pending':
            textColor = 'orange'
            break

          case 'accepted':
            textColor = 'green'
            break

          case 'rejected':
            textColor = 'red'
            break

          default:
            break
        }
        return <p style={{ color: textColor, textTransform: 'uppercase' }}>{cellProps.value}</p>
      },
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: (tableProps: any) => {
        if (tableProps.value && tableProps.value !== 0) {
          return '$' + tableProps?.value?.toLocaleString()
        } else {
          return '$0'
        }
      },
    },
    {
      Header: 'Remark',
      accessor: 'remark',
    },
    {
      Header: 'Requested user',
      accessor: 'requestedUser.displayName',
    },
    {
      Header: 'Requested Date',
      accessor: 'createdAt',
      Cell: (tableProps: any) => {
        const dateString = moment(tableProps.value).format('DD / M / YYYY')
        const fromNow = moment(tableProps.value).fromNow()
        return (
          <Tooltip title={fromNow}>
            <p>{dateString}</p>
          </Tooltip>
        )
      },
      disableGlobalFilter: true,
    },
  ]

  return (
    <div>
      <BaseTable columns={columns} data={budgetRequests} />
    </div>
  )
}

export default BillingCompanyBudgetRequestView
