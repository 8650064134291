import { authAxios } from '../../../helpers/axios'
import { backendUrl } from '../../../env-config'

export const fetchCoachesInMyCompany = async () => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.get(`${backendUrl}/hr/coach`)
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const removeCoachFromCompany = async (coachId: number) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/coach/remove`, {
        coachId,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}
