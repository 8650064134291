import React from 'react'
import css from './PrimaryButton.module.scss'
import { Button, makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

interface PropsI {
  to?: string
  color?: string
  size?: string
  style?: any
  name?: string
  icon?: any
}

const PrimaryButton: React.FC<PropsI> = (props) => {
  const { icon: Icon, to, color, style, name } = props
  const useButtonStyles = makeStyles({
    root: {
      background: color || css.primaryColor,
      borderRadius: '2px',
      minWidth: '140px',
      minHeight: '40px',
      color: 'white',
      transition: 'all 300ms ease-in-out',
      boxShadow: css.primaryBoxShadow,
      display: 'flex',
      padding: '0 20px',
      justifyContent: 'center',
      textDecoration: 'none',

      '&:hover': {
        background: color || css.primaryColor,
        boxShadow: css.buttonBoxShadow,
      },
    },
  })

  const buttonClasses = useButtonStyles()
  if (to) {
    return (
      <Link to={to} style={{ ...style, textDecoration: 'none' }}>
        <Button className={buttonClasses.root}>
          <Icon />
          {name && <p className={css.name}>{name}</p>}
        </Button>
      </Link>
    )
  }

  return (
    <Button style={style} className={buttonClasses.root}>
      <Icon />
      {name && <p className={css.name}>{name}</p>}
    </Button>
  )
}

export default PrimaryButton
