import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'
import { logout } from '../../ducks/auth.duck'
import css from './NotFoundPage.module.scss'
import PageWrapper from '../../components/PageWrapper/PageWrapper'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps {}

const NoPermissionPage: React.FC<PropsI> = (props) => {
  // const {} = props
  return (
    <PageWrapper>
      <div className={css.base}>
        <div className={css.content}>
          <h1>404</h1>
          <p>Page Not Found</p>
        </div>
      </div>
    </PageWrapper>
  )
}

interface MapStatePropsI {}

interface MapDispatchPropsI {
  logoutA: () => any
}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {}
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>
): MapDispatchPropsI => {
  return {
    logoutA: () => dispatch(logout()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NoPermissionPage))
