import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { isAuthenticated } from '../../helpers/authentication'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteProps {
  roles: string[]
}

const AuthRoute: React.FC<PropsI> = ({ children, roles, currentCompany, path, ...rest }) => {
  const isAuthed = isAuthenticated()
  const lsRole = localStorage.getItem('roles')
  const userRoles = lsRole && lsRole.split(',')

  return (
    <Route
      path={path}
      {...rest}
      // @ts-ignore
      render={() => {
        if (!isAuthed) {
          return (
            <Redirect
              to={{
                pathname: '/auth/login',
                state: { from: window.location.pathname },
              }}
            />
          )
        } else {
          if (!userRoles) {
            return (
              <Redirect
                to={{
                  pathname: '/my-companies',
                }}
              />
            )
          }

          let isAllowed: boolean = false
          for (const role of roles) {
            if (userRoles?.includes(role)) {
              isAllowed = true
              break
            }
          }

          if (isAllowed) {
            return children
          } else {
            if (userRoles?.includes('hr')) {
              return (
                <Redirect
                  to={{
                    pathname: '/hr',
                  }}
                />
              )
            }

            if (userRoles?.includes('employee')) {
              return (
                <Redirect
                  to={{
                    pathname: '/employee',
                  }}
                />
              )
            }

            return (
              <Redirect
                to={{
                  pathname: '/no-permission',
                }}
              />
            )
          }

          // return children
        }
      }}
    />
  )
}

interface MapStatePropsI {
  currentUser: any
  currentCompany: any
}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {
    currentUser: state.user.currentUser,
    currentCompany: state.user.currentCompany,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActionI>): MapDispatchPropsI => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute)
