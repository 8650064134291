import React, { useEffect, useState } from 'react'
import css from './YearlyDashboardView.module.scss'
import { fetchDashboardDataYearly, fetchEmployeesDashboardYears } from '../HRDashboard.helper'
import { Bar } from 'react-chartjs-2'
interface PropsI {}

const YearlyDashboardView: React.FC<PropsI> = (props) => {
  const [budgetData, setBidgetData] = useState<any>()
  // const [employeesData, setEmployeesData] = useState<any>()
  useEffect(() => {
    fetchDashboardDataYearly().then((res) => setBidgetData(res.data))
    // fetchEmployeesDashboardYears().then((res) => setEmployeesData(res.data))
  }, [])

  const years = ['2021', '2022', '2023', '2024', '2025']
  const budgetBarData = {
    labels: years,
    datasets: [
      {
        label: '# Utilized Budget',
        data: budgetData?.budget.utilized,
        backgroundColor: css.chartColor2,
      },
      {
        label: '# Allocated Budget',
        data: budgetData?.budget.allocation,
        backgroundColor: css.chartColor1,
      },
    ],
  }

  // const budgetBarOptions = {
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           beginAtZero: true,
  //         },
  //       },
  //     ],
  //   },
  // }

  return (
    <div className={css.base}>
      <div className={css.budget_items}>
        <div className={css.data_item_wrapper}>
          <p>Company Budget</p>
          <Bar data={budgetBarData} />
        </div>
      </div>

      {/*<div className={css.budget_items}>*/}
      {/*  {employeesData?.map((x: any, i: number) => {*/}
      {/*    const employeeBarData = {*/}
      {/*      labels: years,*/}
      {/*      datasets: [*/}
      {/*        {*/}
      {/*          label: '# Utilized Discretionary',*/}
      {/*          data: x.data.discretionaryUtilized,*/}
      {/*          backgroundColor: css.chartColor4,*/}
      {/*        },*/}
      {/*        {*/}
      {/*          label: '# Allocated Discretionary',*/}
      {/*          data: x.data.discretionaryAllocation,*/}
      {/*          backgroundColor: css.chartColor3,*/}
      {/*        },*/}
      {/*        {*/}
      {/*          label: '# Utilized required',*/}
      {/*          data: x.data.requiredUtilized,*/}
      {/*          backgroundColor: css.chartColor2,*/}
      {/*        },*/}
      {/*        {*/}
      {/*          label: '# Allocated required',*/}
      {/*          data: x.data.requiredAllocation,*/}
      {/*          backgroundColor: css.chartColor1,*/}
      {/*        },*/}
      {/*      ],*/}
      {/*    }*/}
      {/*    return (*/}
      {/*      <div key={i} className={css.data_item_wrapper}>*/}
      {/*        <p>{x.name}'s company budget</p>*/}
      {/*        <Bar data={employeeBarData} />*/}
      {/*      </div>*/}
      {/*    )*/}
      {/*  })}*/}
      {/*</div>*/}
    </div>
  )
}

export default YearlyDashboardView
