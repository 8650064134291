import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { vivekaUrl } from '../../env-config'

interface PropsI extends RouteComponentProps {}

const AutoAuthLogoutPage: React.FC<PropsI> = () => {
  useEffect(() => {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    // window.open(`${vivekaUrl}`, '_self')
    window.close()
  }, [])
  return <div />
}

export default withRouter(AutoAuthLogoutPage)
