import React from 'react'
import css from './Stepper.module.scss'
import { ChevronRight } from 'react-feather'

interface StepI {
  title: string
  isSelected?: boolean
  isComplete?: boolean
}

interface PropsI {
  steps: StepI[]
}

const Stepper: React.FC<PropsI> = (props) => {
  const { steps } = props

  return (
    <div className={css.steps}>
      {steps.map((step, index) => {
        return (
          <div key={index} className={css.step}>
            <div
              className={`${css.number_circle} ${step.isSelected && css.selected_number_circle} ${
                step.isComplete && css.completed_number_circle
              }`}
            >
              {index + 1}
            </div>
            <p className={`${css.title} ${step.isSelected && css.selected_title}`}>{step.title}</p>

            {steps.length !== index + 1 && <ChevronRight className={css.right_arrow} />}
          </div>
        )
      })}
    </div>
  )
}

export default Stepper
