import { authAxios } from '../../../helpers/axios'
import { backendUrl } from '../../../env-config'

export const fetchEmployeeEnrollments = async (curriculumId: string, employeeId: string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/curriculum/get-employee-enrollments`, {
        curriculumId,
        employeeId,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const allocateCurriculumAttendeeBudget = async (
  curriculumId: string,
  attendeeId: string,
  amount: number | string,
  remark: string,
  type: string
) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(
        `${backendUrl}/curriculum/allocate-curriculum-employee-budget`,
        {
          curriculumId,
          attendeeId,
          amount,
          remark,
          type,
        }
      )
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchCurriculumEmployee = async (curriculumId: string, attendeeId: string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/curriculum/get-curriculum-employee`, {
        curriculumId,
        attendeeId,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const fetchCurriculumEmployeeBudget = async (curriculumId: string, attendeeId: string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/curriculum/get-curriculum-employee-budget`, {
        curriculumId,
        attendeeId,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}
