import { authAxios } from '../../../helpers/axios'
import { backendUrl } from '../../../env-config'

export const employeeBulkBudgetAllocation = async (employees: any, values: any) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/employee/allocate-bulk-employee-budget`, {
        employees,
        amount: values.amount,
        remark: values.remark,
        type: values.type,
        budgetType: values.budgetType,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}
