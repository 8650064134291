import React, { BaseSyntheticEvent } from 'react'
import { Tab, Tabs } from '@material-ui/core'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import BillingCompanyBudgetView from './BillingCompanyBudgetView/BillingCompanyBudgetView'
import BillingCompanyInvoiceView from './BillingCompanyInvoiceView/BillingCompanyInvoiceView'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import BillingCompanyBudgetRequestView from './BillingCompanyBudgetRequestView/BillingCompanyBudgetRequestView'

interface PropsI extends RouteComponentProps<{ tab: string }> {}

const TABS = ['invoice', 'budget', 'budget-request']

function renderComponentByTab(tab: string) {
  switch (tab) {
    case TABS[0]:
      return <BillingCompanyInvoiceView />

    case TABS[1]:
      return <BillingCompanyBudgetView />

    case TABS[2]:
      return <BillingCompanyBudgetRequestView />

    default:
      return <BillingCompanyBudgetView />
  }
}

function getIndexByParams(tab: string) {
  switch (tab) {
    case TABS[0]:
      return 0

    case TABS[1]:
      return 1

    case TABS[2]:
      return 2

    default:
      return 0
  }
}

const HRBillingPage: React.FC<PropsI> = (props) => {
  const { match } = props

  const selectedTabIndex = getIndexByParams(match.params.tab)

  const onTabChange = (event: BaseSyntheticEvent, tabIndex: number) => {
    const { history } = props
    switch (tabIndex) {
      case 0:
        history.push(`/hr/billing/${TABS[0]}`)
        break

      case 1:
        history.push(`/hr/billing/${TABS[1]}`)
        break

      case 2:
        history.push(`/hr/billing/${TABS[2]}`)
        break
    }
  }

  return (
    <MainDashboardWrapper title="Billing" page="corp-billing">
      <Tabs
        value={selectedTabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={onTabChange}
        aria-label="disabled tabs example"
      >
        <Tab label="Invoice" />
        <Tab label="Budget" />
        <Tab label="Budget Requests" />
      </Tabs>
      {renderComponentByTab(match.params.tab)}

      {/*<BaseTable columns={columns} data={purchases} />*/}
    </MainDashboardWrapper>
  )
}

export default withRouter(HRBillingPage)
