import { authAxios } from '../../../helpers/axios'
import { backendUrl } from '../../../env-config'

export const fetchListing = async (curriculumId: string, listingId: string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.get(
        `${backendUrl}/employee/company/curriculum/${curriculumId}/${listingId}`
      )
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const enrollRequest = async (curriculumId: string, listingId: string) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/employee/company/curriculum/enroll`, {
        curriculumId,
        listingId,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}
