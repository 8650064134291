import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'
import { logout } from '../../ducks/auth.duck'
import css from './NoPermissionPage.module.scss'

import PageWrapper from '../../components/PageWrapper/PageWrapper'
import NavBar from '../../components/NavBar/NavBar'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps {}

const NoPermissionPage: React.FC<PropsI> = (props) => {
  // const {} = props
  return (
    <PageWrapper>
      <NavBar />
      <div className={css.base}>
        <p>
          No permissions to view this page, Please log out and log back in or Change the company
        </p>
        {/*<Button onClick={logoutA}>Logout</Button>*/}
      </div>
    </PageWrapper>
  )
}

interface MapStatePropsI {}

interface MapDispatchPropsI {
  logoutA: () => any
}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {}
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>
): MapDispatchPropsI => {
  return {
    logoutA: () => dispatch(logout()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NoPermissionPage))
