import React, { MouseEventHandler } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import css from './SmallRoundCloseButton.module.scss'

interface PropsI {
  className?: string
  onClick?: MouseEventHandler
}

const SmallRoundCloseButton: React.FC<PropsI> = (props) => {
  const { className, onClick } = props
  return (
    <div onClick={onClick} className={[css.base, className].join(' ')}>
      <FontAwesomeIcon icon={faTimes} />
    </div>
  )
}

export default SmallRoundCloseButton
