import React from 'react'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import css from './PageWrapper.module.scss'
// import { Slide } from '@material-ui/core'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps {}

const PageWrapper: React.FC<PropsI> = (props) => {
  const { children } = props
  // const [isNavOpen, setIsNavOpen] = useState(false)
  return (
    <div className={css.base}>
      {children}

      {/*<button onClick={() => setIsNavOpen(!isNavOpen)}>test </button>*/}

      {/*<Slide direction="right" in={isNavOpen} mountOnEnter unmountOnExit>*/}
      {/*    <div>DSAFDSAFSADFDSA</div>*/}
      {/*</Slide>*/}
    </div>
  )
}

interface MapStatePropsI {}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {}
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActionI>): MapDispatchPropsI => {
  return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageWrapper))
